import React from 'react';
import './App.css';
import Footer from './components/FooterComponent';
import NavCom from './components/NavComponent';

function App() {
  return (
    <div className="App">
      <NavCom/>
     <Footer/>
    </div>
  );
}


export default App;
